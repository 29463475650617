import { TransparentButton } from 'components/Button';
import { IconCloseMedium } from 'icons';
import React from 'react';
import { ToastOptions } from 'react-toastify';
import { ToastWrapper } from './style';
import { DEFAULT_TOAST_MESSAGE_CONFIG, ToastMessageTypeEnum } from './types';

type Props = {
  message: string;
  type: ToastMessageTypeEnum;
};

const CloseButton = ({ closeToast }) => {
  return (
    <TransparentButton type="button" className="notificationCloseButton" onClick={closeToast}>
      <IconCloseMedium />
    </TransparentButton>
  );
};

export const getToastMessageConfig = (): ToastOptions => {
  return {
    ...DEFAULT_TOAST_MESSAGE_CONFIG,
    closeButton: CloseButton,
  };
};

export function ToastMessage({ message, type }: Props) {
  return <ToastWrapper $colorTheme={type}>{message}</ToastWrapper>;
}
