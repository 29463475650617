import { useUserAccountContext } from '@skribi/keycloak-provider/src';
import {
  TaskComplete,
  TaskCompleteStatusEnum,
  TaskPartialStatusEnum,
  TextComplete,
} from '@skribi/openapi/src/text-api';
import { SchoolClass, SchoolClassComplete } from '@skribi/school-utils/src/resources';
import { useSchoolClassActive } from '@skribi/school-utils/src/store/SchoolClass';
import { ModalAlert } from '@skribi/shared/src/components/ModalAlert';
import { LinkBuilder } from '@skribi/shared/src/resources/routes/LinkBuilder';
import { BlackButtonSecondary, MandarinButton, WhiteButton, WhiteButtonSecondary } from 'components/Button';
import { TransparentLink } from 'components/Link';
import { getToastMessageConfig, ToastMessage, ToastMessageTypeEnum } from 'components/ToastMessage';
import { IconArrowDownBlack, IconClose, IconTrash } from 'icons';
import { AlignmentEnum } from 'layouts/OuterWrapper';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Routes } from 'routes';
import { useTextLoadedText } from 'stores';
import { useEditorHelperActions } from 'stores/EditorHelper';
import { useTaskActions, useTaskLoadedTask } from 'stores/Task';
import { ModalTitle } from 'styles';
import { taskCategoryCompleteToTaskPartial, taskCompleteCategoryToTaskPartialCategory } from 'utils/helpers';
import { messages } from './messages';
import { ButtonsWrapper, ButtonWrapper, ModalContentWrapper, Wrapper } from './style';

export function WriteTask() {
  const [t] = useTranslation();
  const navigate = useNavigate();

  const isCommentTab: boolean = window.location.href.includes('who-comment');

  const [showModal, setShowModal] = useState<boolean>(false);
  const [isActivateModalVisible, setIsActivateModalVisible] = useState<boolean>(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState<boolean>(false);

  const { isTeacher } = useUserAccountContext();
  const { setSubmitForm } = useEditorHelperActions();

  const activeTask: TaskComplete | null = useTaskLoadedTask();

  const loadedText: TextComplete | null = useTextLoadedText();

  const { updateTask, deleteTask } = useTaskActions();

  const activeClass: SchoolClass | SchoolClassComplete | null = useSchoolClassActive();

  const [closeButtonLink, setCloseButtonLink] = useState<string | null>(null);

  useEffect(() => {
    if (isTeacher()) {
      setCloseButtonLink(
        isCommentTab
          ? LinkBuilder.TeacherEditTask(activeClass?.id!, activeTask?.id!)
          : LinkBuilder.TeacherTasksOverview(activeClass?.id!),
      );
    } else {
      setCloseButtonLink(
        activeTask?.id || (loadedText && loadedText.submission['taskId'])
          ? LinkBuilder.StudentWriteTextWithTask(loadedText?.submission['taskId']!, loadedText?.id!)
          : Routes.MyTasks,
      );
    }
  }, [isTeacher, activeClass?.id, activeTask?.id, isCommentTab, loadedText]);

  const handleCloseTask = useCallback(() => {
    if (activeTask && activeClass?.id) {
      updateTask({
        id: activeTask?.id!,
        body: {
          due: activeTask?.due,
          textCategory: taskCompleteCategoryToTaskPartialCategory(activeTask?.textCategory!),
          title: activeTask.title,
          description: activeTask.description,
          status: TaskPartialStatusEnum.Done,
          assignees: activeTask.assignees,
        },
        onEnd: (ok: boolean) => {
          if (ok) {
            toast(
              <ToastMessage message={t(messages.taskClosed)} type={ToastMessageTypeEnum.SUCCESS} />,
              getToastMessageConfig(),
            );

            navigate(LinkBuilder.TeacherTasksOverview(activeClass.id!));
          }
        },
      });
    }
  }, [activeTask, updateTask, activeClass?.id, navigate, t]);

  const handleDeleteTask = useCallback(() => {
    if (activeClass?.id) {
      deleteTask({
        id: activeTask?.id!,
        onEnd: (ok: boolean) => {
          if (ok) {
            toast(
              <ToastMessage message={t(messages.taskDeletedSuccesfuly)} type={ToastMessageTypeEnum.SUCCESS} />,
              getToastMessageConfig(),
            );

            navigate(LinkBuilder.TeacherTasksOverview(activeClass.id!));
          }
        },
      });
    }
  }, [deleteTask, activeTask?.id, navigate, activeClass?.id, t]);

  const handleActivateTask = useCallback(() => {
    if (activeTask && activeClass?.id) {
      updateTask({
        id: activeTask?.id!,
        body: {
          due: activeTask?.due,
          textCategory: taskCategoryCompleteToTaskPartial(activeTask?.textCategory!),
          title: activeTask.title,
          description: activeTask.description,
          status: TaskPartialStatusEnum.Active,
          assignees: activeTask.assignees,
        },
        onEnd: (ok: boolean) => {
          if (ok) {
            toast(
              <ToastMessage message={t(messages.taskActivatedSuccessfully)} type={ToastMessageTypeEnum.SUCCESS} />,
              getToastMessageConfig(),
            );

            navigate(LinkBuilder.TeacherTasksOverview(activeClass.id!));
          }
        },
      });
    }
  }, [activeTask, updateTask, activeClass?.id, navigate, t]);

  return (
    <React.Fragment>
      <Wrapper $alignment={AlignmentEnum.LEFT}>
        <ButtonWrapper>
          {activeTask?.status !== TaskCompleteStatusEnum.Done && (
            <MandarinButton onClick={() => setSubmitForm(true)}>
              <IconArrowDownBlack />
              <span>{t(messages.save)}</span>
            </MandarinButton>
          )}

          {activeTask?.status === TaskCompleteStatusEnum.Draft && (
            <WhiteButton type="button" onClick={() => setIsActivateModalVisible(true)}>
              {t(messages.activateTask)}
            </WhiteButton>
          )}
          {!!activeTask && activeTask.status === TaskCompleteStatusEnum.Draft && (
            <WhiteButton type="button" onClick={() => setIsDeleteModalVisible(true)}>
              <IconTrash />
              {t(messages.delete)}
            </WhiteButton>
          )}
        </ButtonWrapper>

        <TransparentLink to={closeButtonLink ? closeButtonLink : Routes.Home}>
          <IconClose title={t(messages.close)} />
        </TransparentLink>
      </Wrapper>

      <ModalAlert isVisible={showModal} handleCloseModal={() => setShowModal(false)} showCloseButton={true}>
        <ModalContentWrapper>
          <ModalTitle>{t(messages.areYouSure)}</ModalTitle>
          <ButtonsWrapper>
            <WhiteButtonSecondary $isSelected={true} type="button" onClick={() => setShowModal(false)}>
              {t(messages.no)}
            </WhiteButtonSecondary>
            <BlackButtonSecondary type="button" onClick={handleCloseTask}>
              {t(messages.yes)}
            </BlackButtonSecondary>
          </ButtonsWrapper>
        </ModalContentWrapper>
      </ModalAlert>

      <ModalAlert
        isVisible={isActivateModalVisible}
        handleCloseModal={() => setIsActivateModalVisible(false)}
        showCloseButton={true}
      >
        <ModalContentWrapper>
          <ModalTitle>{t(messages.areYouSureToActivate)}</ModalTitle>
          <ButtonsWrapper>
            <MandarinButton type="button" onClick={handleActivateTask}>
              {t(messages.yes)}
            </MandarinButton>
            <BlackButtonSecondary type="button" onClick={() => setIsActivateModalVisible(false)}>
              {t(messages.no)}
            </BlackButtonSecondary>
          </ButtonsWrapper>
        </ModalContentWrapper>
      </ModalAlert>

      <ModalAlert
        isVisible={isDeleteModalVisible}
        handleCloseModal={() => setIsDeleteModalVisible(false)}
        showCloseButton={true}
      >
        <ModalContentWrapper>
          <ModalTitle>{t(messages.areYouSureToDelete)}</ModalTitle>
          <ButtonsWrapper>
            <MandarinButton type="button" onClick={handleDeleteTask}>
              {t(messages.yes)}
            </MandarinButton>

            <BlackButtonSecondary type="button" onClick={() => setIsDeleteModalVisible(false)}>
              {t(messages.no)}
            </BlackButtonSecondary>
          </ButtonsWrapper>
        </ModalContentWrapper>
      </ModalAlert>
    </React.Fragment>
  );
}
