import { KeycloakService } from '@skribi/keycloak-provider/src';
import {
  FeedbackBasic,
  NoteBasic,
  NoteBasicTypeEnum,
  NotePartialTypeEnum,
  NoteUserTypeAggregate,
  NoteUserTypeAggregateTypeEnum,
  TaskCompleteGoalEnum,
  TaskCompleteTextCategoryEnum,
  TaskPartialGoalEnum,
  TaskPartialTextCategoryEnum,
  TextAuthorStatusAggregate,
  TextAuthorStatusAggregateStatusEnum,
  TextBasic,
  TextBasicCategoryEnum,
  TextComplete,
  TextCompleteCategoryEnum,
  TextPartialCategoryEnum,
} from '@skribi/openapi/src/text-api';
import { StudentBasic } from '@skribi/school-utils/src/resources';
import { TextCategoryEnum } from '@skribi/shared/src/utils/helpers';
import { AxiosRequestConfig } from 'axios';
import {
  ProcessedNoteReturn,
  ProcessedNoteStatistic,
  ProcessedTaskStatistic,
  ProcessedTextStatistic,
  ProcessedTextStatisticResponse,
} from 'pages/Teacher/ClassStatistics/types';
import { HIGHLIGHT_COLOR_LIST } from 'stores/EditorHelper';
import { NoteBasicWithColor } from 'stores/Note';
import { COORDINATE_CONSTANT } from './constants';

export const ELEMENTS_PER_PAGE: number = 5;

export const getTextCategoryByUrlParam = (category: string): TextBasicCategoryEnum | null => {
  switch (category) {
    case TextCategoryEnum.EMAIL:
      return TextBasicCategoryEnum.Email;

    case TextCategoryEnum.NONFICTIONAL:
      return TextBasicCategoryEnum.NonFictional;

    case TextCategoryEnum.STORY:
      return TextBasicCategoryEnum.Story;

    case TextCategoryEnum.JOKE:
      return TextBasicCategoryEnum.Joke;

    case TextCategoryEnum.LETTER:
      return TextBasicCategoryEnum.Letter;

    case TextCategoryEnum.POEM:
      return TextBasicCategoryEnum.Poem;

    case TextCategoryEnum.FREETEXT:
      return TextBasicCategoryEnum.Free;

    default:
      return null;
  }
};

export const getAxiosRequestConfig = async (): Promise<AxiosRequestConfig> => {
  const tokenUpdateCallback = async accessToken => {
    return accessToken;
  };

  const token: string = (await KeycloakService.updateToken(tokenUpdateCallback)) ?? KeycloakService.getToken();

  const requestOptions: AxiosRequestConfig = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  };

  return requestOptions;
};

export const getTextGoalByString = (goal: string): TaskPartialGoalEnum => {
  switch (goal) {
    case TaskPartialGoalEnum.Argumentation:
      return TaskPartialGoalEnum.Argumentation;
    case TaskPartialGoalEnum.Describing:
      return TaskPartialGoalEnum.Describing;
    case TaskPartialGoalEnum.Other:
      return TaskPartialGoalEnum.Other;
    case TaskPartialGoalEnum.Reporting:
      return TaskPartialGoalEnum.Reporting;
    case TaskPartialGoalEnum.Storytelling:
      return TaskPartialGoalEnum.Storytelling;
    default:
      return TaskPartialGoalEnum.Argumentation;
  }
};

export const getTaskCompleteGoalByString = (goal: string): TaskCompleteGoalEnum => {
  switch (goal) {
    case TaskPartialGoalEnum.Argumentation:
      return TaskCompleteGoalEnum.Argumentation;
    case TaskPartialGoalEnum.Describing:
      return TaskCompleteGoalEnum.Describing;
    case TaskPartialGoalEnum.Other:
      return TaskCompleteGoalEnum.Other;
    case TaskPartialGoalEnum.Reporting:
      return TaskCompleteGoalEnum.Reporting;
    case TaskPartialGoalEnum.Storytelling:
      return TaskCompleteGoalEnum.Storytelling;
    default:
      return TaskCompleteGoalEnum.Argumentation;
  }
};

export const taskCategoryCompleteToPartial = (
  textCategory: TaskCompleteTextCategoryEnum,
): TaskPartialTextCategoryEnum => {
  switch (textCategory) {
    case TaskCompleteTextCategoryEnum.Email:
      return TaskPartialTextCategoryEnum.Email;
    case TaskCompleteTextCategoryEnum.Free:
      return TaskPartialTextCategoryEnum.Free;
    case TaskCompleteTextCategoryEnum.Joke:
      return TaskPartialTextCategoryEnum.Joke;
    case TaskCompleteTextCategoryEnum.Letter:
      return TaskPartialTextCategoryEnum.Letter;
    case TaskCompleteTextCategoryEnum.NonFictional:
      return TaskPartialTextCategoryEnum.NonFictional;
    case TaskCompleteTextCategoryEnum.Poem:
      return TaskPartialTextCategoryEnum.Poem;
    case TaskCompleteTextCategoryEnum.Story:
      return TaskPartialTextCategoryEnum.Story;
    default:
      return TaskPartialTextCategoryEnum.Free;
  }
};

export const textCategoryCompleteToPartial = (textCategory: TextCompleteCategoryEnum): TextPartialCategoryEnum => {
  switch (textCategory) {
    case TextCompleteCategoryEnum.Email:
      return TextPartialCategoryEnum.Email;
    case TextCompleteCategoryEnum.Free:
      return TextPartialCategoryEnum.Free;
    case TextCompleteCategoryEnum.Joke:
      return TextPartialCategoryEnum.Joke;
    case TextCompleteCategoryEnum.Letter:
      return TextPartialCategoryEnum.Letter;
    case TextCompleteCategoryEnum.NonFictional:
      return TextPartialCategoryEnum.NonFictional;
    case TextCompleteCategoryEnum.Poem:
      return TextPartialCategoryEnum.Poem;
    case TextCompleteCategoryEnum.Story:
      return TextPartialCategoryEnum.Story;
    default:
      return TextPartialCategoryEnum.Free;
  }
};

export const taskCompleteTextCategoryToTextPartial = (
  textCategory: TaskCompleteTextCategoryEnum,
): TextCompleteCategoryEnum => {
  switch (textCategory) {
    case TaskCompleteTextCategoryEnum.Email:
      return TextCompleteCategoryEnum.Email;
    case TaskCompleteTextCategoryEnum.Free:
      return TextCompleteCategoryEnum.Free;
    case TaskCompleteTextCategoryEnum.Joke:
      return TextCompleteCategoryEnum.Joke;
    case TaskCompleteTextCategoryEnum.Letter:
      return TextCompleteCategoryEnum.Letter;
    case TaskCompleteTextCategoryEnum.NonFictional:
      return TextCompleteCategoryEnum.NonFictional;
    case TaskCompleteTextCategoryEnum.Poem:
      return TextCompleteCategoryEnum.Poem;
    case TaskCompleteTextCategoryEnum.Story:
      return TextCompleteCategoryEnum.Story;
    default:
      return TextCompleteCategoryEnum.Free;
  }
};

export const taskCompleteCategoryToTaskPartialCategory = (
  textCategory: TaskCompleteTextCategoryEnum,
): TaskPartialTextCategoryEnum => {
  switch (textCategory) {
    case TaskCompleteTextCategoryEnum.Email:
      return TaskPartialTextCategoryEnum.Email;
    case TaskCompleteTextCategoryEnum.Free:
      return TaskPartialTextCategoryEnum.Free;
    case TaskCompleteTextCategoryEnum.Joke:
      return TaskPartialTextCategoryEnum.Joke;
    case TaskCompleteTextCategoryEnum.Letter:
      return TaskPartialTextCategoryEnum.Letter;
    case TaskCompleteTextCategoryEnum.NonFictional:
      return TaskPartialTextCategoryEnum.NonFictional;
    case TaskCompleteTextCategoryEnum.Poem:
      return TaskPartialTextCategoryEnum.Poem;
    case TaskCompleteTextCategoryEnum.Story:
      return TaskPartialTextCategoryEnum.Story;
    default:
      return TaskPartialTextCategoryEnum.Free;
  }
};

export const taskCategoryCompleteToTaskPartial = (
  textCategory: TaskCompleteTextCategoryEnum,
): TaskPartialTextCategoryEnum => {
  switch (textCategory) {
    case TaskCompleteTextCategoryEnum.Email:
      return TaskPartialTextCategoryEnum.Email;
    case TaskCompleteTextCategoryEnum.Free:
      return TaskPartialTextCategoryEnum.Free;
    case TaskCompleteTextCategoryEnum.Joke:
      return TaskPartialTextCategoryEnum.Joke;
    case TaskCompleteTextCategoryEnum.Letter:
      return TaskPartialTextCategoryEnum.Letter;
    case TaskCompleteTextCategoryEnum.NonFictional:
      return TaskPartialTextCategoryEnum.NonFictional;
    case TaskCompleteTextCategoryEnum.Poem:
      return TaskPartialTextCategoryEnum.Poem;
    case TaskCompleteTextCategoryEnum.Story:
      return TaskPartialTextCategoryEnum.Story;
    default:
      return TaskPartialTextCategoryEnum.Free;
  }
};

export const taskCompleteCategoryToTextCompleteCategory = (
  textCategory: TaskCompleteTextCategoryEnum,
): TextCompleteCategoryEnum => {
  switch (textCategory) {
    case TaskCompleteTextCategoryEnum.Email:
      return TextCompleteCategoryEnum.Email;
    case TaskCompleteTextCategoryEnum.Free:
      return TextCompleteCategoryEnum.Free;
    case TaskCompleteTextCategoryEnum.Joke:
      return TextCompleteCategoryEnum.Joke;
    case TaskCompleteTextCategoryEnum.Letter:
      return TextCompleteCategoryEnum.Letter;
    case TaskCompleteTextCategoryEnum.NonFictional:
      return TextCompleteCategoryEnum.NonFictional;
    case TaskCompleteTextCategoryEnum.Poem:
      return TextCompleteCategoryEnum.Poem;
    case TaskCompleteTextCategoryEnum.Story:
      return TextCompleteCategoryEnum.Story;
    default:
      return TextCompleteCategoryEnum.Free;
  }
};

export const NoteBasicTypeEnumToNotePartialTypeEnum = (type: NoteBasicTypeEnum): NotePartialTypeEnum => {
  switch (type) {
    case NoteBasicTypeEnum.Comment:
      return NotePartialTypeEnum.Comment;
    case NoteBasicTypeEnum.Feedback:
      return NotePartialTypeEnum.Feedback;
    default:
      return NotePartialTypeEnum.Comment;
  }
};

export const setCardColorToNotes = (noteList: Array<FeedbackBasic>): Array<NoteBasicWithColor> => {
  // ORDER NOTES BY CREATION DATE TO GET THE RIGHT COLOR SEQUENCE
  const orderedNoteList: Array<NoteBasic> = noteList.sort(function (a, b) {
    if (a.created && b.created) {
      return a.created.getTime() - b.created.getTime();
    } else {
      return -1;
    }
  });

  const noteListWithColor: Array<NoteBasicWithColor> = [];

  let colorIndex = 0;
  orderedNoteList.forEach(note => {
    if (!HIGHLIGHT_COLOR_LIST[colorIndex]) {
      colorIndex = 0;
    }

    noteListWithColor.push({ ...note, cardColor: HIGHLIGHT_COLOR_LIST[colorIndex] });
    colorIndex++;
  });

  return noteListWithColor;
};

export const setCardColorAndOrderNotesByOffset = (noteList: Array<NoteBasic>): Array<NoteBasicWithColor> => {
  return setCardColorToNotes(noteList);
};

export const validateStudentCoordinate = (student: StudentBasic | undefined): boolean => {
  if (!student) {
    return false;
  }

  return !!student && !!student.profile && !!student.profile[COORDINATE_CONSTANT];
};

export const getPublishedAndTotalFromTextList = (
  textStatistics: TextAuthorStatusAggregate[],
): ProcessedTextStatisticResponse => {
  let processedStatisticList: ProcessedTextStatistic[] = [];
  let textCounter: number = 0;

  textStatistics
    .filter(el => el.status !== TextAuthorStatusAggregateStatusEnum.Open)
    .reduce((_, current) => {
      if (current.count) {
        const statisticItem = processedStatisticList.find(el => el.studentId === current.author?.id);

        if (statisticItem) {
          statisticItem.textCount = statisticItem.textCount + current.count;
          if (current.status === TextAuthorStatusAggregateStatusEnum.Published) {
            statisticItem.publishedTextCount = statisticItem.publishedTextCount + current.count;
          }
        }

        if (!statisticItem) {
          processedStatisticList.push({
            studentId: current.author?.id!,
            textCount: current.count,
            publishedTextCount: current.status === TextAuthorStatusAggregateStatusEnum.Published ? current.count : 0,
          });
        }
      }

      return processedStatisticList;
    }, processedStatisticList);

  processedStatisticList.forEach(el => {
    textCounter = el.textCount > textCounter ? el.textCount : textCounter;
  });

  return { processedData: processedStatisticList, textCounter: textCounter };
};

export const processTaskStatistics = (taskStatistics: TextAuthorStatusAggregate[]): ProcessedTaskStatistic[] => {
  const processedStatisticList = taskStatistics.reduce((accumulator: ProcessedTaskStatistic[], current) => {
    if (current.count && current.status) {
      const elementData = accumulator.find(el => el.studentId === current.author?.id);

      if (elementData) {
        switch (current.status) {
          case TextAuthorStatusAggregateStatusEnum.Draft:
            elementData.draft = elementData.draft + current.count;
            break;

          case TextAuthorStatusAggregateStatusEnum.InDiscussion:
            elementData.inDiscussion = elementData.inDiscussion + current.count;
            break;

          case TextAuthorStatusAggregateStatusEnum.InReview:
            elementData.inDiscussion = elementData.inReview + current.count;
            break;

          case TextAuthorStatusAggregateStatusEnum.Published:
            elementData.done = elementData.done + current.count;
            break;
        }
      }

      if (!elementData) {
        accumulator.push({
          studentId: current.author?.id!,
          draft: current.status === TextAuthorStatusAggregateStatusEnum.Draft ? current.count : 0,
          inDiscussion: current.status === TextAuthorStatusAggregateStatusEnum.InDiscussion ? current.count : 0,
          inReview: current.status === TextAuthorStatusAggregateStatusEnum.InReview ? current.count : 0,
          done: current.status === TextAuthorStatusAggregateStatusEnum.Published ? current.count : 0,
        });
      }
    }

    return accumulator;
  }, []);

  return processedStatisticList;
};

export const processNoteStatistics = (noteStatistics: NoteUserTypeAggregate[]): ProcessedNoteReturn => {
  let feedbackCounter: number = 0;
  let commentCounter: number = 0;

  const processedNoteStatistics: ProcessedNoteStatistic[] = noteStatistics.reduce(
    (accumulator: ProcessedNoteStatistic[], current) => {
      if (current.count && current.type && current.user?.id) {
        const elementData = accumulator.find(el => el.studentId === current.user?.id);

        if (elementData) {
          elementData.comment =
            current.type === NoteUserTypeAggregateTypeEnum.Comment ? current.count! : elementData.comment;
          elementData.feedback =
            current.type === NoteUserTypeAggregateTypeEnum.Feedback ? current.count! : elementData.feedback;
        } else {
          accumulator.push({
            studentId: current.user?.id!,
            comment: current.type === NoteUserTypeAggregateTypeEnum.Comment ? current.count! : 0,
            feedback: current.type === NoteUserTypeAggregateTypeEnum.Feedback ? current.count! : 0,
          });
        }
      }
      return accumulator;
    },
    [],
  );

  processedNoteStatistics.forEach(el => {
    feedbackCounter = el.feedback > feedbackCounter ? el.feedback : feedbackCounter;
    commentCounter = el.comment > commentCounter ? el.comment : commentCounter;
  });

  return {
    processedNoteStatistics,
    commentCounter,
    feedbackCounter,
  };
};

export const checkTextIsReported = (text: TextBasic | TextComplete): boolean => {
  const hasTextReports: boolean | undefined = text.reports && text.reports.length > 0;

  if (hasTextReports) {
    return true;
  }

  if (text.noteReports) {
    const hasFeedbackReports: boolean = !!text.noteReports['feedback'] && text.noteReports['feedback'] > 0;
    const hasCommentReports: boolean = !!text.noteReports['comment'] && text.noteReports['comment'] > 0;

    return hasFeedbackReports || hasCommentReports;
  }

  return false;
};

export const fixFileType = (type: string) => {
  return type.replace('jpeg', 'jpg');
};
