import {
  NoteBasic,
  NoteBasicStatusEnum,
  NoteComplete,
  NoteCompleteTypeEnum,
  SubmissionCompleteStatusEnum,
  SubmissionPartialStatusEnum,
  SubmissionPartialTypeEnum,
  TextComplete,
} from '@skribi/openapi/src/text-api';
import { Loader } from '@skribi/shared/src/components/Loader';
import { ModalAlert } from '@skribi/shared/src/components/ModalAlert';
import { LinkBuilder } from '@skribi/shared/src/resources/routes/LinkBuilder';
import { BlackButtonSecondary, MandarinButton, WhiteButton } from 'components/Button';
import { BaloonColorEnum, CommentsBaloon } from 'components/CommentsBaloon';
import { TransparentLink } from 'components/Link';
import { LoadingGif } from 'components/LoadingGif/LoadingGif';
import { TextArea } from 'components/TextArea';
import { getToastMessageConfig, ToastMessage, ToastMessageTypeEnum } from 'components/ToastMessage';
import { IconClose, IconUsers } from 'icons';
import { AlignmentEnum } from 'layouts/OuterWrapper';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTextActions, useTextLoadedText } from 'stores';
import { useNoteActions, useNoteIsRequestInProgress, useNoteNotes } from 'stores/Note';
import { ModalButtonWrapper, ModalTitleLeft } from 'styles';
import { messages } from './messages';
import {
  ButtonGroup,
  ButtonGroupWrapper,
  CloseLinkWrapper,
  FeedbackPopWrapper,
  FinishTextModalWrapper,
  LabelLightContent,
  LitItemIconWrapper,
  MangoWrapper,
  TextAreaWrapper,
  ToolbarItemsList,
  ToolbarItemsListItem,
  Wrapper,
} from './style';

export function TeacherTextWithTask() {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const [isGifVisible, setIsGifVisible] = useState<boolean>(false);

  const [isFinishModalVisible, setIsFinishModalVisible] = useState<boolean>(false);
  const newStatusRef = useRef<SubmissionPartialStatusEnum | null>(null);

  const [isFeedbackPopVisible, setIsFeedbackPopVisible] = useState<boolean>(false);

  const loadedText: TextComplete | null = useTextLoadedText();
  const isTtextRequestInProgress: boolean = useNoteIsRequestInProgress();

  const noteList: Array<NoteBasic> | null = useNoteNotes();
  const isNoteRequestInProgress: boolean = useNoteIsRequestInProgress();

  const isTextInReview: boolean = loadedText?.submission.status === SubmissionCompleteStatusEnum.InReview;

  const { updateSubmissionStatus } = useTextActions();

  const { updateNoteFilter, createNote } = useNoteActions();

  const handleUpdateSubmissionStatus = useCallback(() => {
    if (loadedText?.id && newStatusRef.current) {
      updateSubmissionStatus({
        id: loadedText.id!,
        body: {
          type: SubmissionPartialTypeEnum.Task,
          status: newStatusRef.current!,
        },
        onEnd: (ok: boolean) => {
          if (ok) {
            window.setTimeout(() => {
              setIsGifVisible(false);

              toast(
                <ToastMessage
                  message={t(messages.textUpdated, { textName: loadedText.title })}
                  type={ToastMessageTypeEnum.SUCCESS}
                />,
                getToastMessageConfig(),
              );

              navigate(LinkBuilder.TeacherTaskTracking(loadedText?.classId!, loadedText.submission['taskId']));
            }, 2000);
          } else {
            setIsGifVisible(false);
          }
        },
      });
    }
  }, [
    loadedText?.classId,
    loadedText?.id,
    loadedText?.submission,
    loadedText?.title,
    navigate,
    t,
    updateSubmissionStatus,
  ]);

  const handleSubmitClick = useCallback(() => {
    if (loadedText?.id && !!newStatusRef.current) {
      setIsGifVisible(true);
      createNote({
        body: {
          classId: loadedText.classId,
          message: textAreaRef.current?.value!,
          text: {
            id: loadedText.id!,
            author: {
              id: loadedText.author?.id!,
              name: loadedText.author?.name!,
            },
            title: loadedText.title,
          },
          type: NoteCompleteTypeEnum.Feedback,
        },
        onEnd: (ok: NoteComplete | null) => {
          if (ok) {
            handleUpdateSubmissionStatus();
          }
        },
      });
    }
  }, [
    loadedText?.id,
    loadedText?.title,
    loadedText?.classId,
    createNote,
    loadedText?.author,
    handleUpdateSubmissionStatus,
  ]);

  const handleCloseSubmitModal = useCallback(() => {
    newStatusRef.current = null;
    setIsFinishModalVisible(false);

    if (textAreaRef && textAreaRef.current) {
      textAreaRef.current.value = '';
    }
  }, []);

  useEffect(() => {
    if (loadedText?.submission.status === SubmissionCompleteStatusEnum.InReview) {
      updateNoteFilter(NoteBasicStatusEnum.Submitted);
    } else {
      updateNoteFilter(null);
    }

    return () => {
      updateNoteFilter(NoteBasicStatusEnum.Submitted);
    };
  }, [updateNoteFilter, loadedText?.submission.status]);

  if (isNoteRequestInProgress || isTtextRequestInProgress) {
    return <Loader />;
  }

  return (
    <React.Fragment>
      <Wrapper $alignment={AlignmentEnum.CENTER}>
        <ButtonGroupWrapper>
          {isTextInReview && loadedText ? (
            <ButtonGroup>
              <MandarinButton
                type="button"
                onClick={() => {
                  newStatusRef.current = SubmissionPartialStatusEnum.Published;
                  setIsFinishModalVisible(true);
                }}
              >
                <span>{t(messages.finish)}</span>
              </MandarinButton>

              <WhiteButton
                type="button"
                onClick={() => {
                  newStatusRef.current = SubmissionPartialStatusEnum.Draft;
                  setIsFinishModalVisible(true);
                }}
              >
                {t(messages.reviewAgain)}
              </WhiteButton>
            </ButtonGroup>
          ) : null}
        </ButtonGroupWrapper>

        <ToolbarItemsList>
          <ToolbarItemsListItem $isActive={isFeedbackPopVisible}>
            {loadedText?.submission.status !== SubmissionCompleteStatusEnum.Draft &&
              loadedText?.submission['reviewers'].length > 0 && (
                <LitItemIconWrapper role="button" onClick={() => setIsFeedbackPopVisible(isVisible => !isVisible)}>
                  <IconUsers />
                  <span>{t(messages.feedback)}</span>
                </LitItemIconWrapper>
              )}

            {isFeedbackPopVisible && (
              <FeedbackPopWrapper>
                <MangoWrapper>
                  {loadedText?.submission['reviewers'] && (
                    <LabelLightContent>
                      <span>{t(messages.allowFeedback)}: </span>
                      <span>{loadedText?.submission['reviewers'].map(el => el.name).join(', ')}</span>
                    </LabelLightContent>
                  )}
                </MangoWrapper>
              </FeedbackPopWrapper>
            )}
          </ToolbarItemsListItem>

          <ToolbarItemsListItem>
            <LitItemIconWrapper role="button">
              <CommentsBaloon label={noteList ? noteList.length : 0} theme={BaloonColorEnum.BLACK} />
              <span>{t(messages.comments)}</span>
            </LitItemIconWrapper>
          </ToolbarItemsListItem>
        </ToolbarItemsList>

        <CloseLinkWrapper>
          <TransparentLink to={LinkBuilder.TeacherTaskTracking(loadedText?.classId!, loadedText?.submission['taskId'])}>
            <IconClose title={t(messages.close)} />
          </TransparentLink>
        </CloseLinkWrapper>
      </Wrapper>

      <ModalAlert isVisible={isFinishModalVisible} showCloseButton={true} handleCloseModal={handleCloseSubmitModal}>
        {isGifVisible ? (
          <LoadingGif />
        ) : (
          <FinishTextModalWrapper>
            <ModalTitleLeft>
              {newStatusRef.current === SubmissionPartialStatusEnum.Draft
                ? t(messages.pleaseReviewAgain)
                : t(messages.submittedAndReady)}
            </ModalTitleLeft>

            <TextAreaWrapper>
              <p>{t(messages.messageToAuthor)}</p>
              <TextArea ref={textAreaRef}></TextArea>
            </TextAreaWrapper>

            <ModalButtonWrapper>
              <MandarinButton onClick={handleSubmitClick}>
                {newStatusRef.current === SubmissionPartialStatusEnum.Draft
                  ? t(messages.returnTask)
                  : t(messages.submittedAndReady)}
              </MandarinButton>

              <BlackButtonSecondary onClick={handleCloseSubmitModal}>{t(messages.cancelSending)}</BlackButtonSecondary>
            </ModalButtonWrapper>
          </FinishTextModalWrapper>
        )}
      </ModalAlert>
    </React.Fragment>
  );
}
