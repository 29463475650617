import { useUserAccountContext } from '@skribi/keycloak-provider/src';
import {
  NoteBasic,
  NoteBasicStatusEnum,
  SubmissionCompleteStatusEnum,
  SubmissionPartialStatusEnum,
  SubmissionPartialTypeEnum,
  TaskComplete,
  TaskCompleteStatusEnum,
  TextComplete,
} from '@skribi/openapi/src/text-api';
import { Loader } from '@skribi/shared/src/components/Loader';
import { ModalAlert } from '@skribi/shared/src/components/ModalAlert';
import { LinkBuilder } from '@skribi/shared/src/resources/routes/LinkBuilder';
import { BlackButtonSecondary, MandarinButton, WhiteButton } from 'components/Button';
import { BaloonColorEnum, CommentsBaloon } from 'components/CommentsBaloon';
import { TransparentLink, WhiteLink } from 'components/Link';
import { LoadingGif } from 'components/LoadingGif/LoadingGif';
import { Radio } from 'components/Radio';
import { getToastMessageConfig, ToastMessage, ToastMessageTypeEnum } from 'components/ToastMessage';
import { IconArrowDownBlack, IconClose, IconUsers } from 'icons';
import { AlignmentEnum } from 'layouts/OuterWrapper';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { OriginEnum, Routes, UrlParamsEnum } from 'routes';
import { useTextActions, useTextLoadedText } from 'stores';
import { useEditorHelperActions } from 'stores/EditorHelper';
import { useNoteActions, useNoteIsRequestInProgress, useNoteNotes, useNoteNoteStatusFilter } from 'stores/Note';
import { useTaskLoadedTask } from 'stores/Task';
import { ModalTitleLeft } from 'styles';
import { messages } from './messages';
import {
  ButtonGroup,
  ButtonGroupWrapper,
  ButtonsWrapper,
  CloseLinkWrapper,
  CommentPopWrapper,
  CommentTypeGrid,
  CommentTypeLabel,
  CommentTypeRow,
  FeedbackPopWrapper,
  LabelLightContent,
  LitItemIconWrapper,
  MangoWrapper,
  ModalContentWrapper,
  ModalTextWrapper,
  TextSubmittedMessage,
  ToolbarItemsList,
  ToolbarItemsListItem,
  Wrapper,
} from './style';

export function TextWithTask() {
  const { t } = useTranslation();

  let urlString: string = Routes.MyTasks;
  const urlParams = new URLSearchParams(window.location.search);

  if (urlParams.has(UrlParamsEnum.ORIGIN)) {
    urlString = urlParams.get(UrlParamsEnum.ORIGIN) === OriginEnum.REVIEWER ? Routes.MyTasks : Routes.MyTexts;
  }

  const { getUserId } = useUserAccountContext();

  const navigate = useNavigate();

  const [isFeedbackPopVisible, setIsFeedbackPopVisible] = useState<boolean>(false);
  const [isCommentPopVisible, setIsCommentPopVisible] = useState<boolean>(false);
  const [isChangeStatusModalVisible, setIsChangeStatusModalVisible] = useState<boolean>(false);
  const [isStatusRequestInProgress, setIsStatusRequestInProgress] = useState<boolean>(false);

  const loadedTask: TaskComplete | null = useTaskLoadedTask();

  const loadedText: TextComplete | null = useTextLoadedText();
  const isTtextRequestInProgress: boolean = useNoteIsRequestInProgress();

  const noteList: Array<NoteBasic> | null = useNoteNotes();
  const isNoteRequestInProgress: boolean = useNoteIsRequestInProgress();

  const [filteredNoteList, setFilteredNoteList] = useState<Array<NoteBasic>>([]);

  const noteFilterStatus: NoteBasicStatusEnum | null = useNoteNoteStatusFilter();

  const isTextChecked: boolean =
    loadedText?.submission.status === SubmissionCompleteStatusEnum.InReview ||
    loadedText?.submission.status === SubmissionCompleteStatusEnum.Published;

  const { updateSubmissionStatus } = useTextActions();

  const { setSubmitForm } = useEditorHelperActions();

  const { updateNoteFilter } = useNoteActions();

  const handleFeedbackButtonClick = useCallback(() => {
    if (loadedText?.author?.id === getUserId()) {
      if (isCommentPopVisible) {
        setIsCommentPopVisible(false);
      }
      setIsFeedbackPopVisible(isVisible => !isVisible);
    }
  }, [isCommentPopVisible, getUserId, loadedText?.author?.id]);

  const handleCommentButtonClick = useCallback(() => {
    if (loadedText?.author?.id === getUserId()) {
      if (isFeedbackPopVisible) {
        setIsFeedbackPopVisible(false);
      }

      setIsCommentPopVisible(isVisible => !isVisible);
    }
  }, [isFeedbackPopVisible, getUserId, loadedText?.author?.id]);

  const handleSaveClick = useCallback(() => {
    if (loadedText?.author?.id === getUserId()) {
      if (loadedTask?.status !== TaskCompleteStatusEnum.Done) {
        setSubmitForm(true);
      }
    }
  }, [setSubmitForm, loadedTask?.status, loadedText?.author?.id, getUserId]);

  const handleChangeTextStatus = useCallback(() => {
    if (loadedText?.id) {
      setIsStatusRequestInProgress(true);
      updateSubmissionStatus({
        id: loadedText.id,
        body: {
          type: SubmissionPartialTypeEnum.Task,
          status: SubmissionPartialStatusEnum.InReview,
        },
        onEnd: (ok: boolean) => {
          window.setTimeout(() => {
            setIsStatusRequestInProgress(false);
            if (ok) {
              setIsChangeStatusModalVisible(false);

              toast(
                <ToastMessage
                  message={t(messages.textUpdated, { textName: loadedText.title })}
                  type={ToastMessageTypeEnum.SUCCESS}
                />,
                getToastMessageConfig(),
              );

              navigate(LinkBuilder.StudentMyTexts());
            }
          }, 2000);
        },
      });
    }
  }, [loadedText?.id, updateSubmissionStatus, t, navigate, loadedText?.title]);

  const handleChangeNoteStatusFilter = useCallback(
    (noteStatus: NoteBasicStatusEnum | null) => {
      if (noteList) {
        if (noteStatus) {
          setFilteredNoteList(noteList.filter(el => el.status === noteStatus));
        }

        if (!noteStatus) {
          setFilteredNoteList(noteList);
        }
      } else {
        setFilteredNoteList([]);
      }

      updateNoteFilter(noteStatus);
    },
    [noteList, updateNoteFilter],
  );

  useEffect(() => {
    handleChangeNoteStatusFilter(noteFilterStatus);

    return () => {
      updateNoteFilter(NoteBasicStatusEnum.Submitted);
    };
  }, [noteFilterStatus, handleChangeNoteStatusFilter, updateNoteFilter]);

  if (isNoteRequestInProgress || isTtextRequestInProgress) {
    return <Loader />;
  }

  return (
    <Wrapper $alignment={AlignmentEnum.CENTER}>
      <ButtonGroupWrapper>
        {isTextChecked ? (
          <TextSubmittedMessage>{t(messages.textIsSubmitted)}</TextSubmittedMessage>
        ) : loadedText?.author?.id === getUserId() ? (
          <ButtonGroup>
            <MandarinButton
              disabled={loadedTask?.status === TaskCompleteStatusEnum.Done}
              type="button"
              onClick={handleSaveClick}
            >
              <IconArrowDownBlack />
              <span>{t(messages.save)}</span>
            </MandarinButton>

            {loadedTask?.status === TaskCompleteStatusEnum.Done ? (
              <WhiteButton disabled={true} type="button">
                {t(messages.getFeedback)}
              </WhiteButton>
            ) : (
              loadedText.submission.status === SubmissionCompleteStatusEnum.Draft && (
                <WhiteLink to={LinkBuilder.StudentAddTextReviewers(loadedText?.id!)}>
                  {t(messages.getFeedback)}
                </WhiteLink>
              )
            )}

            {loadedText.submission.status !== SubmissionCompleteStatusEnum.Open &&
            loadedText.submission.status !== SubmissionCompleteStatusEnum.Published ? (
              <WhiteButton
                disabled={loadedTask?.status === TaskCompleteStatusEnum.Done}
                type="button"
                onClick={() => setIsChangeStatusModalVisible(true)}
              >
                {t(messages.submit)}
              </WhiteButton>
            ) : null}
          </ButtonGroup>
        ) : null}
      </ButtonGroupWrapper>

      <ToolbarItemsList>
        <ToolbarItemsListItem $isActive={isFeedbackPopVisible}>
          {loadedText?.submission.status !== SubmissionCompleteStatusEnum.Draft &&
            loadedText?.submission['reviewers'].length > 0 && (
              <LitItemIconWrapper role="button" onClick={handleFeedbackButtonClick}>
                <IconUsers />
                <span>{t(messages.feedback)}</span>
              </LitItemIconWrapper>
            )}

          {isFeedbackPopVisible && (
            <FeedbackPopWrapper>
              <MangoWrapper>
                {loadedText?.submission['reviewers'] && (
                  <LabelLightContent>
                    <span>{t(messages.allowFeedback)}: </span>
                    <span>{loadedText.submission['reviewers'].map(el => el.name).join(', ')}</span>
                  </LabelLightContent>
                )}
                {loadedText?.submission.status === SubmissionCompleteStatusEnum.InDiscussion && (
                  <WhiteLink to={LinkBuilder.StudentAddTextReviewers(loadedText?.id!)}>{t(messages.change)}</WhiteLink>
                )}
              </MangoWrapper>
            </FeedbackPopWrapper>
          )}
        </ToolbarItemsListItem>

        <ToolbarItemsListItem $isActive={isCommentPopVisible}>
          <LitItemIconWrapper role="button" onClick={handleCommentButtonClick}>
            <CommentsBaloon label={filteredNoteList.length} theme={BaloonColorEnum.BLACK} />
            <span>{t(messages.comments)}</span>
          </LitItemIconWrapper>

          {isCommentPopVisible && (
            <CommentPopWrapper>
              <MangoWrapper>
                <CommentTypeGrid>
                  <CommentTypeRow>
                    <Radio
                      id="open"
                      checked={noteFilterStatus === NoteBasicStatusEnum.Submitted}
                      onClick={() => handleChangeNoteStatusFilter(NoteBasicStatusEnum.Submitted)}
                    />
                    <CommentTypeLabel htmlFor="open">{t(messages.open)}</CommentTypeLabel>
                  </CommentTypeRow>

                  <CommentTypeRow>
                    <Radio
                      id="completed"
                      checked={noteFilterStatus === NoteBasicStatusEnum.Done}
                      onClick={() => handleChangeNoteStatusFilter(NoteBasicStatusEnum.Done)}
                    />
                    <CommentTypeLabel htmlFor="completed">{t(messages.completed)}</CommentTypeLabel>
                  </CommentTypeRow>

                  <CommentTypeRow>
                    <Radio id="none" checked={!noteFilterStatus} onClick={() => handleChangeNoteStatusFilter(null)} />
                    <CommentTypeLabel htmlFor="none">{t(messages.none)}</CommentTypeLabel>
                  </CommentTypeRow>
                </CommentTypeGrid>
              </MangoWrapper>
            </CommentPopWrapper>
          )}
        </ToolbarItemsListItem>
      </ToolbarItemsList>

      <CloseLinkWrapper>
        <TransparentLink to={urlString}>
          <IconClose title={t(messages.close)} />
        </TransparentLink>
      </CloseLinkWrapper>

      <ModalAlert
        isVisible={isChangeStatusModalVisible}
        showCloseButton={!isStatusRequestInProgress}
        handleCloseModal={() => setIsChangeStatusModalVisible(false)}
        noWidth={true}
      >
        {isStatusRequestInProgress ? (
          <LoadingGif />
        ) : (
          <ModalContentWrapper>
            <ModalTitleLeft>{t(messages.openText)}</ModalTitleLeft>
            <ModalTextWrapper>
              <p>{t(messages.paragraph1)}</p>
              <p>{t(messages.paragraph2)}</p>
              <p>{t(messages.paragraph3)}</p>
            </ModalTextWrapper>
            <ButtonsWrapper>
              <MandarinButton type="button" onClick={handleChangeTextStatus}>
                {t(messages.requestRelease)}
              </MandarinButton>

              <BlackButtonSecondary type="button" onClick={() => setIsChangeStatusModalVisible(false)}>
                {t(messages.keep)}
              </BlackButtonSecondary>
            </ButtonsWrapper>
          </ModalContentWrapper>
        )}
      </ModalAlert>
    </Wrapper>
  );
}
