import {
  TaskComplete,
  TaskCompleteStatusEnum,
  TaskPartialStatusEnum,
  UserStubPartial,
} from '@skribi/openapi/src/text-api';
import { SchoolClass, SchoolClassComplete } from '@skribi/school-utils/src/resources';
import { useSchoolClassActive } from '@skribi/school-utils/src/store/SchoolClass';
import { LinkBuilder } from '@skribi/shared/src/resources/routes/LinkBuilder';
import { MandarinButton } from 'components/Button';
import { TransparentLink, WhiteLinkBoldLabel } from 'components/Link';
import { getToastMessageConfig, ToastMessage, ToastMessageTypeEnum } from 'components/ToastMessage';
import { IconClose } from 'icons';
import { AlignmentEnum } from 'layouts/OuterWrapper';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useTaskActions, useTaskLoadedTask } from 'stores/Task';
import { taskCompleteCategoryToTaskPartialCategory } from 'utils/helpers';
import { messages } from './messages';
import { ButtonsWrapper, Wrapper } from './style';

export function TaskTracking() {
  const { t } = useTranslation();

  const activeClass: SchoolClass | SchoolClassComplete | null = useSchoolClassActive();
  const loadedTask: TaskComplete | null = useTaskLoadedTask();

  const { updateTask } = useTaskActions();

  const handleCloseTask = useCallback(() => {
    if (loadedTask) {
      updateTask({
        id: loadedTask?.id!,
        body: {
          assignees: loadedTask?.assignees ? loadedTask.assignees.map(el => el as UserStubPartial) : [],
          due: loadedTask?.due!,
          textCategory: taskCompleteCategoryToTaskPartialCategory(loadedTask.textCategory),
          title: loadedTask.title,
          status: TaskPartialStatusEnum.Done,
        },
        onEnd: (ok: boolean) => {
          if (ok) {
            toast(
              <ToastMessage message={t(messages.taskClosed)} type={ToastMessageTypeEnum.SUCCESS} />,
              getToastMessageConfig(),
            );
          }
        },
      });
    }
  }, [loadedTask, t, updateTask]);

  return (
    <React.Fragment>
      <Wrapper $alignment={AlignmentEnum.CENTER}>
        <ButtonsWrapper>
          <WhiteLinkBoldLabel to={LinkBuilder.TeacherEditTask(activeClass?.id!, loadedTask?.id!)}>
            {t(messages.edit)}
          </WhiteLinkBoldLabel>
          {loadedTask?.status === TaskCompleteStatusEnum.Active && (
            <MandarinButton type="button" onClick={handleCloseTask}>
              {t(messages.closeTask)}
            </MandarinButton>
          )}
        </ButtonsWrapper>

        <TransparentLink aria-label={t(messages.close)} to={LinkBuilder.TeacherTasksOverview(activeClass?.id!)}>
          <IconClose />
        </TransparentLink>
      </Wrapper>
    </React.Fragment>
  );
}
