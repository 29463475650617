import { ToastContainer } from 'react-toastify';
import styled from 'styled-components';
import { FontBody, FontBodyBold } from 'styles';
import { ToastMessageTypeEnum } from './types';

type ToastMessageProps = {
  $colorTheme: ToastMessageTypeEnum;
};

export const ToastWrapper = styled.div<ToastMessageProps>`
  display: grid;
  grid-template-columns: ${p => p.theme.toRem(197)} 1fr;
  column-gap: ${p => p.theme.toRem(16)};
  padding: ${p => p.theme.toRem(20)} ${p => p.theme.toRem(20)} ${p => p.theme.toRem(20)} ${p => p.theme.toRem(32)};

  background: ${p =>
    p.$colorTheme === ToastMessageTypeEnum.SUCCESS
      ? p.theme.color.primary_turquoise
      : p.$colorTheme === ToastMessageTypeEnum.ERROR
      ? p.theme.color.secondary_papaya
      : p.theme.color.white};

  ${FontBody};
  text-align: left;
  color: ${p => p.theme.color.primary_black};
`;

export const Wrapper = styled.div`
  position: absolute;

  .ToastMessageBody {
    border-radius: 5px;
    min-height: fit-content !important;

    .Toastify__toast-body {
      padding: 0;
      ${FontBodyBold};
      color: ${p => p.theme.color.primary_white};
    }

    .Toastify__close-button {
      color: ${p => p.theme.color.primary_white};
      opacity: 1;
    }
  }
`;

export const CustomToastMessageContainer = styled(ToastContainer)`
  /*
  * The &&& means that the style will be apllyed for the 3 childrens of the class
  * Reference: https://fkhadra.github.io/react-toastify/how-to-style/#how-to-style-with-styled-components
  */
  .Toastify__toast {
    background: transparent;
    border: none;
    box-shadow: none;
  }

  .Toastify__toast--error {
    background: ${p => p.theme.color.primary_red};
  }

  .Toastify__toast-icon {
    display: none;
  }

  .newsNotification {
    min-width: unset;
    padding: unset;
    background: transparent;
    position: relative;

    .notificationCloseButton {
      position: absolute;
      top: 27px;
      right: 25px;
    }
  }
`;
