import { ToastContainer } from 'react-toastify';
import styled from 'styled-components';
import { FontBodyBold } from 'styles';

export const AppContainer = styled.div`
  height: var(--vh100);
  display: grid;
  justify-content: center;

  grid-template-rows: 1fr auto;

  grid-column-gap: 0;
  grid-template-columns: 1fr;

  grid-template-areas:
    'page--main'
    'page--footer';
`;

interface ContentContainerProps {
  $bgColor?: string;
}

export const ContentContainer = styled.div<ContentContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: center;

  position: relative;
  grid-area: page--main;
  width: 100%;
  background: ${p => (p.$bgColor ? p.$bgColor : p.theme.color.primary_white)};
  overflow-y: auto;
`;

export const Footer = styled.footer`
  grid-area: page--footer;

  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 -6px 12px 0 rgba(105, 105, 105, 0.15);
  width: 100%;
  background: ${p => p.theme.color.primary_turquoise};
`;

export const ToasMessageStylerWrapper = styled.div`
  position: absolute;

  .ToastMessageBody {
    border-radius: 5px;
    min-height: fit-content !important;

    .Toastify__toast-body {
      padding: 0;
      ${FontBodyBold};
      color: ${p => p.theme.color.primary_white};
    }

    .Toastify__close-button {
      color: ${p => p.theme.color.primary_white};
      opacity: 1;
    }
  }
`;

export const CustomToastMessageContainer = styled(ToastContainer)`
  /*
  * The &&& means that the style will be apllyed for the 3 childrens of the class
  * Reference: https://fkhadra.github.io/react-toastify/how-to-style/#how-to-style-with-styled-components
  */

  .Toastify__toast {
    background: transparent;
    border: none;
    box-shadow: none;
  }

  .Toastify__toast--error {
    background: ${p => p.theme.color.primary_red};
  }

  .Toastify__toast-icon {
    display: none;
  }

  .newsNotification {
    min-width: unset;
    padding: unset;
    background: transparent;
    position: relative;

    .notificationCloseButton {
      position: absolute;
      top: 27px;
      right: 25px;
    }
  }
`;
