import { TextBasicCategoryEnum } from '@skribi/openapi/src/text-api/models';
import { URLSearchParams } from 'url';
import { AppParamsType } from '../store/Config';

export const getAppConfigParam = (paramName: AppParamsType): string => {
  const appConfig = (window as any).appConfig;

  if (appConfig && appConfig[paramName]) {
    return appConfig[paramName];
  } else {
    return '';
  }
};

export const getEditorSaveInterval = (): number => {
  const appConfig = (window as any).appConfig;

  if (appConfig && appConfig['editorSaveIntervalInSeconds']) {
    return parseInt(appConfig['editorSaveIntervalInSeconds']) * 1000;
  } else {
    return 12000;
  }
};

export enum TextCategoryEnum {
  STORY = 'geschichte',
  POEM = 'gedicht',
  JOKE = 'witz',
  LETTER = 'brief',
  FREETEXT = 'freier-text',
  NONFICTIONAL = 'sachtext',
  EMAIL = 'email',
  OTHER = 'other',
}

export const getUrlParamByTextCategory = (category: string): TextCategoryEnum | null => {
  switch (category) {
    case TextBasicCategoryEnum.Email:
      return TextCategoryEnum.EMAIL;

    case TextBasicCategoryEnum.NonFictional:
      return TextCategoryEnum.NONFICTIONAL;

    case TextBasicCategoryEnum.Story:
      return TextCategoryEnum.STORY;

    case TextBasicCategoryEnum.Joke:
      return TextCategoryEnum.JOKE;

    case TextBasicCategoryEnum.Letter:
      return TextCategoryEnum.LETTER;

    case TextBasicCategoryEnum.Poem:
      return TextCategoryEnum.POEM;

    case TextBasicCategoryEnum.Free:
      return TextCategoryEnum.FREETEXT;

    default:
      return null;
  }
};

export const slugify = (str: string): string => {
  return str
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, '')
    .replace(/[\s_-]+/g, '-')
    .replace(/^-+|-+$/g, '');
};

export const createQueryString = (parameters: any, query: URLSearchParams): URLSearchParams => {
  for (const key in parameters) {
    if (Array.isArray(parameters[key])) {
      parameters[key].forEach((value: any) => query.append(key, value.toString()));
    } else {
      query.set(key, parameters[key]);
    }
  }

  return query;
};
